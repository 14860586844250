import React, { Fragment } from "react";
import { BrowserRouter } from "react-router-dom";
import { UserContextProvider } from "./context/userContext";

//import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers';

import {ThemeProvider,StyledEngineProvider,createTheme} from "@mui/material/styles";

//import StylesProvider from "@mui/styles/StylesProvider";

import "./App.css";
import LoginDialog from "./dialogs/LoginDialog";
//import AuthenticateDialog from "./dialogs/AuthenticateDialog";
import CssBaseline from "@mui/material/CssBaseline";
import AAAppBar from "./components/AAAppBar";
import MainPage from "./pages/MainPage";

function App() {
    const theme = createTheme();

    //<userContext.Provider value={{ currentUser: currentUser, onUserChange: handleUserChange }}>
    return (
            <UserContextProvider>
                <BrowserRouter>
                        <StyledEngineProvider injectFirst>
                            <ThemeProvider theme={theme}>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <Fragment>
                                        <div style={{ display: "flex" }}>
                                        <CssBaseline />
                                        <AAAppBar />
                                        <MainPage />
                                        </div>
                                    <LoginDialog
                                        title="Idox ADS Security Login"
                                        message="Please enter your username and password."
                                        version={process.env.REACT_APP_SECURITY_VERSION}
                                    />
                                </Fragment>
                                </LocalizationProvider>
                            </ThemeProvider>
                        </StyledEngineProvider>
                </BrowserRouter>
            </UserContextProvider>
    );
}

export default App;
