/**************************************************************************************************
//
//  Description: wizardContext
//
//  Copyright:    © 2023 Idox Limited
//
//--------------------------------------------------------------------------------------------------
//
//  Modification History:
//
//  Version Date     Modifier            Issue# Description
//#region Version 1.0.0.0 changes
//    001   06.01.23 Peter Bryden
//#endregion Version 1.0.0.0 changes
//
//-------------------------------------------------------------------------------------------------*/

import React, { useState } from "react";

const EditUserWizardContext = React.createContext();
EditUserWizardContext.displayName = "editUserContext";

function EditUserWizardContextProvider({props, children }) {

    /*
  "password": "dummyuser",
  "firstName": "dummy",
  "lastName": "user",
  "auditName": "dummyuser_pjb_only",
  "email": "dummyuser@example.com",
  "organisation": "IDOX",
  "rights": ["User"],
  "extraInformation": []
    */

    const [editUser, setEditUser] = useState(null);

  
    function SetEditUser(userProperties)
    {
        setEditUser(userProperties);
    }    

    function GetEditUser()
    {
        return editUser;
    }    

    function ClearEditUser() {
        SetEditUser({
            id: "",
            password: "",
            firstName: "",
            lastName: "",
            auditName: "",
            email: "",
            organisation: "",
            rights: [],
            extraInformation: []
        })
    }

return (
    <EditUserWizardContext.Provider value={{ GetEditUser, SetEditUser, ClearEditUser  }}>
        {children}
    </EditUserWizardContext.Provider>
)

}

export { EditUserWizardContextProvider, EditUserWizardContext }
