import { FormatDateTime } from "../../utils/HelperUtils";

export const UserTableColumns = [
  {
    headerName: "Id",
    field: "id",
    sortable: false,
    hide: true,
    filterable: false,
    headerClassName: "data-grid-header",
  },
  {
    headerName: "Organisation",
    field: "organisation",
    sortable: true,
    filterable: true,
    flex: 0.2,
    minWidth: 120,
    width: 160,
    headerClassName: "data-grid-header",
  },
  {
    headerName: "Audit Name",
    field: "auditName",
    sortable: true,
    filterable: true,
    flex: 0.25,
    minWidth: 100,
    width: 110,
    headerClassName: "data-grid-header",
  },
  {
    headerName: "First Name",
    field: "firstName",
    sortable: true,
    filterable: true,
    flex: 0.25,
    minWidth: 80,
    width: 100,
    headerClassName: "data-grid-header",
  },
  {
    headerName: "Last Name",
    field: "lastName",
    sortable: true,
    filterable: true,
    flex: 0.25,
    minWidth: 80,
    width: 100,
    headerClassName: "data-grid-header",
  },
  {
    headerName: "Email",
    field: "email",
    sortable: true,
    filterable: true,
    flex: 0.3,
    minWidth: 100,
    width: 140,
    headerClassName: "data-grid-header",
  },
  {
    headerName: "Created",
    field: "created",
    sortable: true,
    filterable: true,
    flex: 0.25,
    valueFormatter: (param) => {
      return FormatDateTime(param.value);
    },
    minWidth: 120,
    width: 140,
    headerClassName: "data-grid-header",
  },
  {
    headerName: "Updated",
    field: "lastUpdated",
    sortable: true,
    filterable: true,
    flex: 0.25,
    valueFormatter: (param) => {
      return FormatDateTime(param.value);
    },
    minWidth: 120,
    width: 140,
    headerClassName: "data-grid-header",
  },
];

export const UserInfoColumns = [
  {
    headerName: "Id",
    field: "id",
    sortable: false,
    hide: true,
    filterable: false,
  },
  {
    headerName: "Key",
    field: "key",
    sortable: false,
    hide: false,
    filterable: false,
    flex: 0.5,
  },
  {
    headerName: "Value",
    field: "value",
    sortable: false,
    hide: false,
    filterable: false,
    flex: 0.5,
  },
];
