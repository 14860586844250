import React, { Fragment, useEffect, useState} from "react";
import { StyledWizardDialog, StyledWizardDialogTitle } from "./StyledWizardDialog";
import {
  DialogActions,Grid,
  DialogContent,TextField,Button,
  FormControl, FormGroup, FormLabel, FormControlLabel, Checkbox,
  InputLabel, Select,
  Tabs,Tab,
} from "@mui/material";
import { FormInputStyle } from "../../utils/AAStyles";
import { useTheme } from '@mui/material/styles';
import ContinueButton from "../../components/continueButton";
import BackButton from "../../components/backButton";

import rights from "../../data/rights.json";


const GridTabPanelAdvanced = ({changeRightsHandler, editUserDetails, showPanel }) =>  {

  const [userRights, setUserRights] = useState(editUserDetails && editUserDetails.rights);

  const [selectedRight, setSelectedRight] = useState("");
  const [editedRight, setEditedRight] = useState("");
  const [trimmedRight, setTrimmedRight] = useState("");

  const [addEnabled, setAddEnabled] = useState(false);
  const [updateEnabled, setUpdateEnabled] = useState(false);
  const [removeEnabled, setRemoveEnabled] = useState(false);

  useEffect(() => {
    setEditedRight(selectedRight);
  },[selectedRight])


  useEffect(() => {
    setTrimmedRight(editedRight.trim());
  },[editedRight])

  useEffect(() => {
    const checkCondition = ((x) => x === trimmedRight);
    const bTrimmedRightPopulated = trimmedRight && trimmedRight.length > 0;
    const bSelectedRightPopulated = selectedRight && selectedRight.length > 0;

    setAddEnabled(bTrimmedRightPopulated && userRights.findIndex(checkCondition) < 0)
    setUpdateEnabled(bSelectedRightPopulated && bTrimmedRightPopulated && userRights.findIndex(checkCondition) < 0)
    setRemoveEnabled(bTrimmedRightPopulated &&  userRights.findIndex(checkCondition) >= 0)

  },[trimmedRight])


  const updateRightHandler = () => {
    if(trimmedRight && trimmedRight.length > 0)
    {
        if(selectedRight && userRights.findIndex(x => x === trimmedRight) < 0)
          changeRightsHandler([...userRights.filter(x => x !== selectedRight), trimmedRight])
    }
  }

  const addRightHandler = () => {
    if(trimmedRight && trimmedRight.length > 0)
    {  
      if(userRights.findIndex(x => x === trimmedRight) < 0)
        changeRightsHandler([...userRights, trimmedRight]); 
    }
  }

  const removeRightHandler = () => {
    if(trimmedRight && trimmedRight.length > 0)
      changeRightsHandler(userRights.filter(x => x !== trimmedRight))
  }


  useEffect(()=> {
    if(editUserDetails)
      setUserRights(editUserDetails.rights)
  },[editUserDetails])

  return showPanel ? (
    <Grid container justifyContent="flex-start" alignItems="center" sx={{ minHeight:"30vh", minWidth:"100%"}}>
      <Grid item xs={12} >
        <InputLabel shrink htmlFor="select-multiple-rights" xs={12}>Rights</InputLabel>
        <Select
          multiple
          native
          onChange={(e) => setSelectedRight(e.target.value)}
          label="Native"
          inputProps={{
            id: 'select-user-rights',
          }}
          sx={{minWidth:"95%"}}
          xs={12}
         >
          {userRights && userRights.map((name) => (
            <option key={name} value={name} >
              {name}
            </option>
          ))}
        </Select>
      </Grid>
      <Grid item xs={6} >
        <TextField
              id="change-user-right-text"
              sx={FormInputStyle() && {minWidth:"95%"}}
              type="text"
              variant="outlined"
              margin="dense"
              size="small"
              value={editedRight}
              onChange={(e) => setEditedRight(e.target.value)}
              xs={12}
            />
      </Grid>

        <Grid item xs={2}>
            <Button disabled={!updateEnabled} onClick={(e) => updateRightHandler()} variant="outlined" sx={FormInputStyle()}>Update</Button>
        </Grid>
        <Grid item xs={2}>
            <Button disabled={!addEnabled} onClick={(e) => addRightHandler()} variant="outlined" sx={FormInputStyle()}>Add</Button>
        </Grid>
        <Grid item xs={2}>
          <Button disabled={!removeEnabled} onClick={(e) => removeRightHandler()} variant="outlined" sx={FormInputStyle()}>Remove</Button>
        </Grid>
    </Grid>
  
  ) : <Fragment />;
}

const GridTabPanelBasic = ({changeRightsHandler, editUserDetails, showPanel, metaControls}) =>  {

  const [userRights, setUserRights] = useState(editUserDetails && editUserDetails.rights);

  useEffect(()=> {
    if(editUserDetails)
      setUserRights(editUserDetails.rights)
  },[editUserDetails])


  const checkBoxHandler = (checked,metaName) => 
  {
      //console.log(`checkBoxHandler::${metaName}`,checked,metaName);
      const metaIdx = metaControls.findIndex(x => x.name === metaName)
      if(metaIdx >= 0)
      {
        changeRightsHandler(
             (!checked) ? userRights.filter(x => x !== metaControls[metaIdx].right)
                        : [...userRights, metaControls[metaIdx].right]
          ); 
      }
  }  

  return showPanel ? (
    <FormControl sx={{ m: 3 }} component="fieldset" variant="standard">
    <FormLabel component="legend">Assign responsibility</FormLabel>
    <FormGroup>
      {metaControls.map((x) => {
      return (
        <FormControlLabel key={x.name} control={
          <Checkbox 
                    checked={userRights.findIndex(y => y === x.right) >= 0} 
                    onChange={(e) => checkBoxHandler(e.target.checked, x.name)} 
                    name={`${x.name}`} 
                    inputProps={{ 'aria-label': 'controlled' }} />
        }
        label={`${x.label}`}
        />
      )
      })
      }
    </FormGroup>
    </FormControl>
  ) : <Fragment />
}




const UserRightsDialog = ({userDetails,closeDialogHandler,continueHandler, backHandler,isSuperAdmin}) =>  {
  const theme = useTheme();

  const [editUserDetails, setEditUserDetails] = useState(userDetails);
  
  const [superadmin, setSuperAdmin] = useState(false);
  const [organisationadmin, setOrganisationAdmin] = useState(false);
  const [administrator, setAdministrator] = useState(false);
  const [user, setUser] = useState(false);
  

  const superadminMeta = { name: "superadmin", label : "Super Admin", update: setSuperAdmin, property:superadmin, right:"SuperAdministrator"};
  const organisationAdminMeta = {name:"orgadmin", label : "Organisation Admin", update: setOrganisationAdmin, property:organisationadmin, right:"OrganisationAdministrator"};
  const adminMeta = {name:"admin", label : "Administrator", update: setAdministrator, property:administrator, right:"Administrator"};
  const userMeta = {name:"user", label : "User", update:setUser, property:user, right:"User"};

  const alwaysOnCheckboxes = [organisationAdminMeta,adminMeta,userMeta]
  const metaControls = isSuperAdmin ? [superadminMeta, ...alwaysOnCheckboxes] : alwaysOnCheckboxes;
  const basicRights = 0;
  const [tabValue, setTabValue] = useState(basicRights);
  const [fileOptions, setFileOptions] = useState([])  


  const onCancelHandler = (event,reason) => { 
    if(reason !== "backdropClick") 
      closeDialogHandler();
  };
  
  useEffect(() => {
        
    if(editUserDetails && editUserDetails.rights)
    { 
        //console.log("userrights",editUserDetails, editUserDetails.rights)     
        metaControls.map((x) => 
        { 
           const bIsChecked = editUserDetails.rights.findIndex( (elem) => elem === x.right) >= 0;
           if(x.property !== bIsChecked)
            x.update(bIsChecked);
        });  
    }
    
  },[editUserDetails])

  const userRightsChangeHandler= (userRights) => {
    setEditUserDetails({...editUserDetails, rights: userRights})
  }

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };


  useEffect(()=>{
    if(!fileOptions || fileOptions.length === 0)
        setFileOptions(rights);
  },[fileOptions])
 
  return (
    <StyledWizardDialog
      onClose={onCancelHandler}
      open
      //aria-labelledby="confirm-edit-loss-dialog"
      fullWidth
      disableEnforceFocus
      disableEscapeKeyDown
      maxWidth="sm"
    >
      <StyledWizardDialogTitle id="customized-dialog-title" onClose={onCancelHandler} title="Update User Rights"  />
      <DialogContent sx={{ marginTop: theme.spacing(1) }}>

      <Tabs value={tabValue} onChange={handleTabChange} aria-label="">
          <Tab label="Basic" index={basicRights} />
          {
            fileOptions && fileOptions.length > 0 && fileOptions.map((x,idx) => {
              return <Tab key={x.name} label={x.name} index={1+idx} />
            })
          }
          <Tab label="Advanced" index={1+fileOptions.length} />
        </Tabs>

       <GridTabPanelBasic showPanel={tabValue === basicRights} changeRightsHandler={(rights) => userRightsChangeHandler(rights)} editUserDetails={editUserDetails} metaControls={metaControls}/>

       <GridTabPanelAdvanced showPanel={tabValue === 1+fileOptions.length} changeRightsHandler={(rights) => userRightsChangeHandler(rights)} editUserDetails={editUserDetails} />

       {
          fileOptions && fileOptions.length > 0 && fileOptions.map((x,idx) => {
            return <GridTabPanelBasic key={x.name} showPanel={tabValue === (1+idx)} changeRightsHandler={(rights) => userRightsChangeHandler(rights)} editUserDetails={editUserDetails} metaControls={x.options}/>
          })
       }

      </DialogContent>

      <DialogActions sx={{ marginBottom: theme.spacing(1) }}>
        <BackButton backHandler={() => backHandler(editUserDetails)} />
        <ContinueButton continueHandler={()=>continueHandler(editUserDetails)} enabled={true}/>
      </DialogActions>
    </StyledWizardDialog>
  );
}

export default UserRightsDialog;