import React, { useContext, useState, useEffect } from "react";
import { EditUserWizardContext } from "./../../context/editUserWizardContext ";
import { UserContext } from "../../context/userContext";
import { UpdateUser, CreateUser } from "../../configuration/AAConfig_Security";

import UserDetailsDialog from "./UserDetailsDialog"
import UserRightsDialog from "./UserRightsDialog"
import UserInfoDialog from "./UserInfoDialog";
import UserConfirmDialog from "./UserConfirmDialog";
import ResetPasswordDialog from "./ResetPasswordDialog";

const EditUserWizardDialog = ({closeDialogHandler, updateUserHandler, userDetails, errors }) =>  {

  const { GetEditUser, SetEditUser } = useContext(EditUserWizardContext);   
  const { currentToken, currentUser } = useContext(UserContext);

  const [currentStep, setCurrentStep] = useState("UserDetails")
  const [editUserDetails, setEditUserDetails] = useState(userDetails);
  const [editUserPassword, setEditUserPassword] = useState();
  const [errorText] = useState(errors);
  
  const isSuperAdmin = currentUser && currentUser.rights.findIndex(x => x === "SuperAdministrator") >= 0;
  const userOrganisation = (!isSuperAdmin) ? currentUser.organisation : "";

  const UserDetailStep = "UserDetails";
  const UserRightStep = "UserRights";
  const UserInfoStep = "UserInfo";
  const UserPasswordStep = "UserPassword";
  const UserConfirmStep = "UserConfirm";


 const passwordStep = () => {

  if(editUserDetails && editUserDetails.id.length > 0)
  {
    if(currentStep === UserInfoStep)
        return UserConfirmStep;
    if(currentStep === UserConfirmStep)    
        return UserInfoStep
  }
  
  return "UserPassword"
}

useEffect(() => {
  //if(editUserDetails && editUserDetails.organisation.length === 0)
  //  setEditUserDetails({...editUserDetails, organisation:})

  if(editUserDetails !== GetEditUser())
      SetEditUser(editUserDetails);

}, [editUserDetails]);


useEffect(() => {
   setEditUserDetails({...editUserDetails, password:editUserPassword});
}, [editUserPassword]);



 const updateEditUserDetails = (details) => {
   if(details != editUserDetails)
      setEditUserDetails(details);
 }


 const processStep = (nextStep, currentStepHandler) => {
    setCurrentStep(nextStep);   
    if(currentStepHandler)
        currentStepHandler();
  }

  useEffect(() => {
    if(errorText)
        console.log("error text ", errorText);
  
  }, [errorText]);
  

  const saveUserDetails = async() => {
    
    if(editUserDetails && editUserDetails.id.length > 0)
    {
        const response = await UpdateUser(currentToken,editUserDetails);
        updateUserHandler(editUserDetails, errorText);
    }
    else
    {
      const response = await CreateUser(currentToken,editUserDetails);
      updateUserHandler(editUserDetails, errorText);
    }    
  }

  const ContinueStepHandler = (returnData) =>
  {
      switch(currentStep)
      {
         case UserDetailStep    : return processStep(UserRightStep, () => updateEditUserDetails(returnData));      
         case UserRightStep     : return processStep(UserInfoStep, () => updateEditUserDetails(returnData));      
         case UserInfoStep      : return processStep(passwordStep(), () => updateEditUserDetails(returnData)); 
         case UserPasswordStep  : return processStep(UserConfirmStep, () => setEditUserPassword(returnData)); 
         case UserConfirmStep   : return saveUserDetails(); 
         default :                return alert("No Handler found for",currentStep);
      }
  }

  const BackStepHandler = (returnData) =>
  {
      switch(currentStep)
      {
         case UserRightStep     : return processStep(UserDetailStep, () => updateEditUserDetails(returnData));    
         case UserInfoStep      : return processStep(UserRightStep, () => updateEditUserDetails(returnData));
         case UserPasswordStep  : return processStep(UserInfoStep, null);
         case UserConfirmStep   : return processStep(passwordStep(), null);
         default              : return processStep(UserDetailStep, null);
      }
  }

  const getCurrentDialog = () =>{

      switch(currentStep)  
      {
          case UserDetailStep     : return (<UserDetailsDialog userDetails={editUserDetails}
                                                          closeDialogHandler={closeDialogHandler} 
                                                          continueHandler={(details) => ContinueStepHandler(details)}
                                                          isSuperAdmin={isSuperAdmin}
                                                          defaultOrganisation={userOrganisation}
                                      />)


          case UserRightStep      : return (<UserRightsDialog userDetails={editUserDetails} 
                                                        closeDialogHandler={closeDialogHandler}        
                                                        continueHandler={(details) => ContinueStepHandler(details)} 
                                                        backHandler={(details) => BackStepHandler(details)} 
                                                        isSuperAdmin={isSuperAdmin}
                                      />)

          case UserInfoStep       : return (<UserInfoDialog userDetails={editUserDetails} 
                                                        closeDialogHandler={closeDialogHandler}        
                                                        continueHandler={(details) => ContinueStepHandler(details)} 
                                                        backHandler={(details) => BackStepHandler(details)}
                                      />)

          case UserPasswordStep   : return (<ResetPasswordDialog 
                                                        closeDialogHandler={closeDialogHandler}        
                                                        continueHandler={(password) => ContinueStepHandler(password)} 
                                                        backHandler={(password) => BackStepHandler(password)}
                                      />)

          case UserConfirmStep    : return (<UserConfirmDialog userDetails={editUserDetails}
                                                        closeDialogHandler={closeDialogHandler}        
                                                        continueHandler={() => ContinueStepHandler()} 
                                                        backHandler={() => BackStepHandler()}
                                                        errorText={errorText}
                                        />)
      }
  }

  return getCurrentDialog();
}

export default EditUserWizardDialog;