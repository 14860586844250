import React from "react";
import { Grid } from "@mui/material";
import AADataTable from "./AADataTable"


const UserListGrid = ({columns, rows, height, onDoubleClick, sortColumn, updateSortColumn }) => 
{
return !rows ?
    (
        <h1>Loading</h1>
    )
    :    
    (
        <Grid container justifyContent="flex-start" sx={{height:{height}}}>
            <Grid item xs={12} sx={{ mt: 2, height: {height} }}>
                <AADataTable columnData={columns} rowData={rows} height={height} handleDoubleClick={onDoubleClick}
                    sortCol={sortColumn} updateSortCol={updateSortColumn}
                />
            </Grid>
        </Grid>
    );
}

export default UserListGrid;