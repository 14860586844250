import React, { useState, useEffect } from "react";
import { Grid, TextField, Button, Tooltip, Typography } from "@mui/material";
import AAMultiSelect from "./AAMultiSelect";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import AddIcon from "@mui/icons-material/Add";

import { AddUserStyle, FormInputStyle } from "../utils/AAStyles";
import { EmptyUserFilter } from "./Data/DataUserFilters";

const UserActions = ({ organisations, onFilterChange, filters, handleNewUserClick }) => {
  const [userOrganisations] = useState(organisations);
  const [activeStates] = useState([{text : "Active", val: true }, {text: "Inactive", val: false}]);
  const [userFilter, setUserFilter] = useState(filters);

  useEffect(() => {
    if (onFilterChange) onFilterChange(userFilter);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userFilter]);

  const handleResetFilters = () => {
    setUserFilter(EmptyUserFilter);
  };

  return (
    <Grid container spacing={2} direction="row" alignItems="center" justifyContent="center">
      <Grid item>
        <AAMultiSelect
          labelName="Organisations"
          listItems={userOrganisations}
          selected={userFilter.organisation}
          OnSelectChange={(selectedItems) => {
            setUserFilter({ ...userFilter, organisation: selectedItems });
          }}
        />
      </Grid>

      <Grid item xs={1}>
        <TextField
          id="filter-auditname"
          label="Audit Name"
          variant="outlined"
          margin="dense"
          size="small"
          sx={FormInputStyle()}
          value={userFilter.auditName}
          onChange={(e) => setUserFilter({ ...userFilter, auditName: e.target.value })}
        />
      </Grid>

      <Grid item xs={1}>
        <TextField
          id="filter-firstname"
          label="First Name"
          variant="outlined"
          margin="dense"
          size="small"
          sx={FormInputStyle()}
          value={userFilter.firstName}
          onChange={(e) => setUserFilter({ ...userFilter, firstName: e.target.value })}
        />
      </Grid>

      <Grid item xs={1}>
        <TextField
          id="filter-lastname"
          label="Last Name"
          variant="outlined"
          margin="dense"
          size="small"
          sx={FormInputStyle()}
          value={userFilter.lastName}
          onChange={(e) => setUserFilter({ ...userFilter, lastName: e.target.value })}
        />
      </Grid>

      <Grid item xs={1}>
        <TextField
          id="filter-email"
          fullWidth
          label="Email"
          variant="outlined"
          margin="dense"
          size="small"
          sx={FormInputStyle()}
          value={userFilter.email}
          onChange={(e) => setUserFilter({ ...userFilter, email: e.target.value })}
        />
      </Grid>

      <Grid item>
        <AAMultiSelect
          labelName="Active"
          listItems={activeStates.map((state) => state.text)}
          selected={userFilter.active}
          OnSelectChange={(selectedItems) => {
            setUserFilter({ ...userFilter, active: selectedItems });
          }}
        />
      </Grid>

      <Grid item xs sx={{ mt: 1, cursor:'pointer' }}>
        <Tooltip title="Clear Filters" arrow placement="top">
          <RestartAltIcon onClick={handleResetFilters} />
        </Tooltip>
      </Grid>

      <Grid item>
        <Button variant="contained" sx={AddUserStyle()} startIcon={<AddIcon />} onClick={handleNewUserClick}>
          <Typography variant="body2">Add User</Typography>
        </Button>
      </Grid>
    </Grid>
  );
};

export default UserActions;
