/* #region header */
/**************************************************************************************************
//
//  Description: Aligned Assets Navigation Bar Header component
//
//  Copyright:    © 2021 Aligned Assets Limited
//
//--------------------------------------------------------------------------------------------------
//
//  Modification History:
//
//  Version Date     Modifier            Issue# Description
//#region Version 1.0.0.0 changes
//    001   02.07.21 Sean Flook         WI39??? Initial Revision.
//#endregion Version 1.0.0.0 changes
//
//--------------------------------------------------------------------------------------------------
/* #endregion header */

/* #region imports */

import React from "react";
import { Grid, Typography } from "@mui/material";

/* #endregion imports */

const AANavHeader = () => {
  return (
    <Grid container spacing={0} alignItems={"center"} alignContent={"left"} sx={{ border: 0, width: "100%" }}>
      <Grid item sx={{ ml: 8, mt: 2 }}>
        <Typography>Idox ADS Security Admin</Typography>
      </Grid>
    </Grid>
  );
};

export default AANavHeader;
