import React, { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { Box, Stack, Typography } from "@mui/material";
import { adsBlueA, adsLightGreyB, adsLightGreyC, adsMidGreyA, adsWhite } from "../utils/ADSColours";

const AADataTable = ({ columnData, rowData, handleDoubleClick, sortCol, updateSortCol }) => {
  const [sortModel, setSortModel] = useState(sortCol);

  const handleSortChange = (model) => {
    /* if statement to prevent the infinite loop by confirming model is 
         different than the current sortModel state */

    if (JSON.stringify(model) !== JSON.stringify(sortModel)) {
      setSortModel(model);
    }
  };

  useEffect(() => {
    if (updateSortCol) updateSortCol(sortModel);
  }, [sortModel, updateSortCol]);

  return (
    <div style={{ height: "95%", width: "100%" }}>
      <Stack sx={{ backgroundColor: adsWhite, color: adsMidGreyA, borderBottom: `0px solid ${adsLightGreyB}` }}>
        <Typography variant="subtitle2" sx={{ color: adsBlueA }}>
          Users
        </Typography>
      </Stack>
      <div style={{ display: "flex", height: "100%" }}>
        <div style={{ flexGrow: 1 }}>
          <Box
            sx={{
              height: "100%",
              mb: "26px",
              "& .data-grid-header": { backgroundColor: adsLightGreyC, color: adsMidGreyA },
              "& .MuiDataGrid-iconSeparator": { display: "none" },
            }}
          >
            <DataGrid
              rows={!rowData ? [] : rowData}
              columns={!columnData ? [] : columnData}
              autoPageSize
              disableColumnMenu
              disableColumnSelector
              disableDensitySelector
              hideFooterSelectedRowCount
              density="compact"
              editMode="row"
              pagination
              onCellDoubleClick={(params, event) => {
                if (handleDoubleClick) handleDoubleClick(params);
                if (!event.ctrlKey) {
                  event.defaultMuiPrevented = true;
                }
              }}
              sortModel={sortModel}
              onSortModelChange={(model) => handleSortChange(model)}
            />
          </Box>
        </div>
      </div>
    </div>
  );
};

export default AADataTable;
