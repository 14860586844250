import React from "react";
import { Button } from "@mui/material";
import LockResetIcon from "@mui/icons-material/LockReset";
import { adsBlueA, adsLightBlue, adsWhite } from "../utils/ADSColours";

const ChangePasswordButton = ({continueHandler, title, enabled}) =>  {

    return (
        <Button
        disabled={!enabled}
        onClick={continueHandler}
        sx={{
          color: adsWhite,
          backgroundColor: adsBlueA,
          textTransform: "none",
          pl:2,
          pr:2,
          "&:hover": {
            backgroundColor: adsLightBlue,
            color: adsWhite,
          },
          "&.Mui-disabled": {
            background: "#eaeaea",
            color: "#c0c0c0"
          }
        }}
        startIcon={<LockResetIcon />}
      >
        {title ? title : "Change Password"}
        
      </Button>
    );
}

export default ChangePasswordButton;

