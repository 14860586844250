import React, { useEffect, useState } from "react";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
import Select from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
      marginLeft: 0,
    },
  },
};

const AAMultiSelect = ({ listItems, OnSelectChange, labelName, selected }) => {
  const [selectedItems, setSelectedItems] = useState(selected ? selected : []);
  const [itemList] = useState(listItems ? listItems : []);

  useEffect(() => {
    setSelectedItems(selected || []);
  }, [selected]);

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedItems(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  useEffect(() => {
    if (OnSelectChange) OnSelectChange(selectedItems);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedItems]);

  return (
    <div>
      <FormControl sx={{ mt: 0.75, width: 144 }} variant="outlined" size="small">
        <InputLabel id="multiselect-label">{labelName}</InputLabel>
        <Select
          labelId="multiselect-label"
          id="multiselect"
          multiple
          value={selectedItems}
          onChange={handleChange}
          input={<OutlinedInput label={labelName} />}
          renderValue={(selected) => selected.join(", ")}
          MenuProps={MenuProps}
          sx={{ height: 40 }}
        >
          {itemList.map((item) => (
            <MenuItem key={item} value={item}>
              <Checkbox checked={selectedItems.indexOf(item) > -1} />
              <ListItemText primary={item} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
};

export default AAMultiSelect;
