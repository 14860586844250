import React, { useEffect, useState} from "react";
import { StyledWizardDialog, StyledWizardDialogTitle } from "./StyledWizardDialog";
import {
  Typography,
  DialogActions,
  DialogContent,
  Grid,
  Alert,
  AlertTitle
} from "@mui/material";
import { FormRowStyle } from "../../utils/AAStyles";
import { useTheme } from '@mui/material/styles';
import ContinueButton from "../../components/continueButton";
import BackButton from "../../components/backButton";

const UserConfirmDialog = ({userDetails,closeDialogHandler,continueHandler,backHandler,errorText}) =>  {
  const theme = useTheme();

  const [contineEnabled] = useState(true);
  const [userView, setUserView] = useState();

  const onCancelHandler = (event,reason) => { 
    if(reason !== "backdropClick") 
      closeDialogHandler();
  };

  const ShowUserError = () => {
    console.log("errorText::",errorText);
    if(errorText && errorText.length > 0)
      return (
        <Alert severity="error">
          <AlertTitle>Error</AlertTitle>
          {errorText}
        </Alert>
      )
  };

  useEffect(() => {
    setUserView([
      {name:"FirstName", value:userDetails.firstName, type:"single"},
      {name:"LastName", value:userDetails.lastName, type:"single"},
      {name:"Email", value:userDetails.email, type:"single"},
      {name:"AuditName", value:userDetails.auditName, type:"single"},
      {name:"Active", value:userDetails.active, type:"single"},
      {name:"Rights", value:userDetails.rights, type:"multi"},
      {name:"Extra Information", value:userDetails.extraInformation, type:"multikeypair"},
    ])
  },[userDetails])

  return (
    <StyledWizardDialog
      onClose={onCancelHandler}
      open
      //aria-labelledby="confirm-edit-loss-dialog"
      fullWidth
      disableEnforceFocus
      disableEscapeKeyDown
      maxWidth="md"
    >
      <StyledWizardDialogTitle id="customized-dialog-title" onClose={onCancelHandler} title="Confirm User" />
      <DialogContent sx={{ marginTop: theme.spacing(0) }}>

            {userView && userView.map((x,idx) => { return (
            <Grid
              key={`key_${idx}`}
              container
              justifyContent="flex-start"
              alignItems="center"
              sx={FormRowStyle()}
            >
              <Grid item xs={3}>
                <Typography
                  id={`aa-text-label-${x.name}`}
                  variant="body2"
                  color="initial"
                  align="left"
                  //sx={TextFieldLabel(hasContinued && !isFieldValid(x,`${x.property}`)) }
                >
                {x.name}
                </Typography>
              </Grid>

              <Grid item xs={9}>
                <Typography
                  id={`aa-text-label-${idx}`}
                  variant="body2"
                  color="initial"
                  align="left"
                  //sx={TextFieldLabel(hasContinued && !isFieldValid(x,`${x.property}`)) }
                >
                {
                  (x.type === "multikeypair") 
                  ? `${JSON.stringify(x.value)}`
                  : `${x.value}`
                }
                </Typography>
              </Grid>

            </Grid>
            )}
            )}
          
          <ShowUserError />

      </DialogContent>

      <DialogActions sx={{ marginBottom: theme.spacing(1) }}>
        <BackButton backHandler={() =>backHandler()} />
        <ContinueButton title={"Save User"} continueHandler={() => continueHandler()} enabled={contineEnabled} />
      </DialogActions>
    </StyledWizardDialog>
  );
}

export default UserConfirmDialog;