import React, { Fragment } from "react";

import PageRouting from "../PageRouting";
import AANavContent from "../components/AANavContent";

const MainPage = () => {
  return (
    <Fragment>
      <AANavContent />
      <main
        style={{
          flexGrow: 1,
          paddingLeft: "10px",
          paddingRight: "10px",
        }}
      >
        <PageRouting />
      </main>
    </Fragment>
  );
};

export default MainPage;
