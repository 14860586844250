import React from "react";
import PropTypes from "prop-types";
import {
  Button,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import LoginIcon from "@mui/icons-material/Login";
import CloseIcon from "@mui/icons-material/Close";
import { useTheme } from '@mui/material/styles';

ConfirmationDialog.propTypes = {
  title: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
};

function ConfirmationDialog({ title, message, OkClickHandler, CancelClickHandler }) {
  const theme = useTheme();

  return (
    <Dialog
      open={true}
      aria-labelledby="confirm-edit-loss-dialog"
      fullWidth
      maxWidth="xs"
      disableEnforceFocus 
    >
      <DialogTitle
        id="confirm-edit-loss-dialog"
        sx={{ color: "#FFFFFF", backgroundColor: "#2A6EBB" }}
      >
        {title ? title : "Confirm"}
      </DialogTitle>
      <DialogContent sx={{ marginTop: theme.spacing(1) }}>
          <Typography variant="body1">{message}</Typography>
      </DialogContent>
      <DialogActions sx={{ marginBottom: theme.spacing(1) }}>
        <Button
          onClick={OkClickHandler}
          autoFocus
          variant="contained"
          sx={{
            color: "#FFFFFF",
            backgroundColor: "#2A6EBB",
            "&:hover": {
              backgroundColor: "#00AEEF",
              color: "#FFFFFF",
            },
          }}
          startIcon={<LoginIcon />}
        >
          Ok
        </Button>
        <Button
          onClick={CancelClickHandler}
          sx={{
            color: "#2A6EBB",
            "&:hover": {
              backgroundColor: "#00AEEF",
              color: "#FFFFFF",
            },
          }}
          startIcon={<CloseIcon />}
        >
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ConfirmationDialog;
