/* #region header */
/**************************************************************************************************
//
//  Description: Aligned Assets User Avatar component
//
//  Copyright:    © 2021 Aligned Assets Limited
//
//--------------------------------------------------------------------------------------------------
//
//  Modification History:
//
//  Version Date     Modifier            Issue# Description
//#region Version 1.0.0.0 changes
//    001   02.07.21 Sean Flook                 Initial Revision.
//#endregion Version 1.0.0.0 changes
//
//--------------------------------------------------------------------------------------------------
/* #endregion header */

/* #region imports */

import React, { useContext, useState } from "react";
//import { UserAdminRoute } from "../PageRouting";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { Card, CardActions, CardContent, IconButton, Popover, Stack, Typography } from "@mui/material";
import { ActionIconStyle } from "../utils/AAStyles";
import { useTheme } from "@mui/material/styles";
import { UserContext } from "../context/userContext";
import { adsBlueA, adsMidGreyA, adsWhite } from "../utils/ADSColours";
import LogoutIcon from "@mui/icons-material/Logout";
import PasswordIcon from "@mui/icons-material/Password";

/* #endregion imports */

const AAUserAvatar = (props) => {
  const { logoff, currentUser } = useContext(UserContext);
  const theme = useTheme();

  const [anchorEl, setAnchorEl] = useState(null);
  const settingsOpen = Boolean(anchorEl);
  const settingsPopoverId = settingsOpen ? "ads-settings-popover" : undefined;

  const handleSettingsClose = () => {
    setAnchorEl(null);
  };

  function getActionStyle() {
    return {
      backgroundColor: adsWhite,
      color: adsMidGreyA,
      "&:hover": {
        color: adsBlueA,
      },
    };
  }

  function handleAvatarClick() {
    if(currentUser) 
      setAnchorEl(document.getElementById("imanage-user-settings"));
  }

  function handleResetPasswordClick() {
    console.log(currentUser);
  }

  function GetSettingCards() {
    return (
      <Stack direction="row" spacing={0.5}>
        {currentUser && (
          <Card variant="outlined">
            <CardContent>
              <Stack direction="row" spacing={1} alignItems="center" justifyContent="center">
                {currentUser && (
                  <Typography
                    sx={{ fontWeight: "bold" }}
                  >{`${currentUser.firstName} ${currentUser.lastName}`}</Typography>
                )}
              </Stack>
            </CardContent>
            <CardActions>
              <Stack
                sx={{ ml: theme.spacing(2), mr: theme.spacing(2), mb: theme.spacing(2) }}
                direction="column"
                spacing={1}
                alignItems="flex-start"
              >
                <IconButton
                  sx={getActionStyle()}
                  id={`ads-action-button-${"buttonLabel" ? "buttonLabel".toLowerCase().replaceAll(" ", "-") : ""}`}
                  size="small"
                  disabled={false}
                  onClick={() => {
                    logoff(true);
                  }}
                >
                  <LogoutIcon />
                  <Typography
                    variant="body2"
                    sx={{
                      pl: theme.spacing(0.5),
                      pr: theme.spacing(1),
                    }}
                  >
                    Log Out
                  </Typography>
                </IconButton>

                <IconButton
                  sx={getActionStyle()}
                  id={`ads-action-button-${"buttonLabel" ? "buttonLabel".toLowerCase().replaceAll(" ", "-") : ""}`}
                  size="small"
                  disabled={false}
                  onClick={handleResetPasswordClick}
                >
                  <PasswordIcon />
                  <Typography
                    variant="body2"
                    sx={{
                      pl: theme.spacing(0.5),
                      pr: theme.spacing(1),
                    }}
                  >
                    Change Password
                  </Typography>
                </IconButton>
              </Stack>
            </CardActions>
          </Card>
        )}
      </Stack>
    );
  }

  return (
    <>
      <IconButton
        id="imanage-user-settings"
        aria-label="profile"
        onClick={handleAvatarClick}
        size="large"
        sx={{ position: "absolute", bottom: 20 }}
      >
        <AccountCircleIcon fontSize="large" sx={ActionIconStyle()} />
      </IconButton>

      <Popover
        id={settingsPopoverId}
        open={settingsOpen}
        anchorEl={anchorEl}
        onClose={handleSettingsClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        transformOrigin={{ vertical: "bottom", horizontal: "left" }}
      >
        {GetSettingCards()}
      </Popover>
    </>
  );
};

export default AAUserAvatar;
