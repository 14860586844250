/* #region imports */

import React, { useContext } from "react";
import { Route, Routes } from "react-router-dom";
import { UserContext } from "./context/userContext";

import HomePage from "./pages/HomePage";

/* #endregion imports */

export const HomeRoute = "/";

const PageRouting = () => {
  const { currentUser, showLogin } = useContext(UserContext);

  return (
    currentUser &&
    !showLogin && (
      <Routes>
        <Route path="*" element={<HomePage />} />
      </Routes>
    )
  );
};

export default PageRouting;
