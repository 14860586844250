import React from "react";
import { Button } from "@mui/material";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const BackButton = ({backHandler}) =>  {

    return (
        <Button
        onClick={backHandler}
        sx={{
          color: "#2A6EBB",
          "&:hover": {
            backgroundColor: "#00AEEF",
            color: "#FFFFFF",
          },
        }}
        startIcon={<ArrowBackIcon />}
      >Back
      </Button>
    );
}

export default BackButton;