import React, { useContext, useState, useEffect, Fragment } from "react";
import { useTheme } from "@mui/material/styles";
import { Snackbar, Alert, Grid, Card, Typography, CardContent, IconButton, Tooltip } from "@mui/material";
import { UserContext } from "../context/userContext";
import { UserTableColumns } from "../components/Data/DataTableColumns_User";

import UserListGrid from "../components/userListGrid";
import ResetPasswordDialog from "../dialogs/user/ResetPasswordDialog";
import EditUserWizard from "../dialogs/EditUserWizard";
import UserActions from "../components/UserActions";

import { GetUsers, UpdateUserPassword, DeleteUser } from "../configuration/AAConfig_Security";
import { EmptyUserFilter } from "../components/Data/DataUserFilters";
import RestartAlt from "@mui/icons-material/RestartAlt";
import DeleteIcon from "@mui/icons-material/Delete";
import { adsBlueA } from "../utils/ADSColours";
import ConfirmationDialog from "../dialogs/ConfirmationDialog";

const HomePage = () => {
  const { currentToken, logoff } = useContext(UserContext);
  const theme = useTheme();

  const [errorText, setErrorText] = useState(null);
  const [userData, setUserData] = useState(null);
  const [filteredData, setFilteredData] = useState(null);
  const [userOrganisations, setUserOrganisations] = useState([]);
  const [editedUserId, setEditedUserId] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [showResetPassword, setShowResetPassword] = useState();
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState();
  const [showEditUser, setShowEditUser] = useState();
  const [editedUser, setEditedUser] = useState();
  const [errorAlert, setErrorAlert] = useState(false);
  const [successAlert, setSuccessAlert] = useState(false);
  const [errorMsg, setErrorMsg] = useState();
  const [successMsg, setSuccessMsg] = useState();
  const [activeStates] = useState([
    { text: "Active", state: true },
    { text: "Inactive", state: false },
  ]);
  const [userFilter, setUserFilter] = useState(EmptyUserFilter);
  const [dataSort, setDataSort] = useState();

  const [removedUserName,setRemovedUserName] = useState();

  useEffect(() => {
    async function GetUserData() {
      if (isLoading && !userData) {
        const usersInfo = await GetUsers(currentToken, (err) => setErrorText(err));
        setUserData(usersInfo);
      }
    }

    if (isLoading && !errorText) {
      GetUserData();
    } else {
      if (errorText) {
        logoff();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);

  useEffect(() => {
    console.log("showDeleteConfirmation", showDeleteConfirmation);
  }, [showDeleteConfirmation]);

  useEffect(() => {
    if(editedUserId)
    { const user = userData.find((user) => user.id === editedUserId);
      if(user)
        setRemovedUserName(user.auditName);
    }
  }, [editedUserId]);

  useEffect(() => {
    if (!errorText) setIsLoading(!userData);

    if (userData) {
      //setFilteredData(userData);
      const orgs = [...new Set(userData.map((item) => item.organisation))];
      setUserOrganisations(orgs);
      FilterTheData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData]);

  const FilterTheData = () => {
    if (userFilter !== undefined && userFilter !== null) {
      const filters = [
        {
          condition: (item) => item.firstName.toLowerCase().includes(userFilter.firstName.toLowerCase()),
          enabled: userFilter.firstName.length > 0,
        },
        {
          condition: (item) => item.lastName.toLowerCase().includes(userFilter.lastName.toLowerCase()),
          enabled: userFilter.lastName.length > 0,
        },
        {
          condition: (item) => item.auditName.toLowerCase().includes(userFilter.auditName.toLowerCase()),
          enabled: userFilter.auditName.length > 0,
        },
        {
          condition: (item) => item.email.toLowerCase().includes(userFilter.email.toLowerCase()),
          enabled: userFilter.email.length > 0,
        },
        {
          condition: (item) => userFilter.organisation.includes(item.organisation),
          enabled: userFilter.organisation.length > 0,
        },
        {
          condition: (item) => //userFilter.active.includes(item.active),
            
            activeStates
              .filter((element) => userFilter.active.indexOf(element.text) !== -1)
              .map((obj) => obj.state)
              .includes(item.active),
            
          enabled: userFilter.active.length > 0,
        },
      ];

      const conditions = filters.filter((item) => item.enabled).map((item) => item.condition);

      if (userData && userData.length > 0) {
        if (conditions.length > 0) setFilteredData(userData.filter((item) => conditions.every((f) => f(item))));
        else setFilteredData(userData);
      }
    } else {
      setFilteredData(userData);
    }
  };

  useEffect(() => {
    if (userFilter) FilterTheData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userFilter]);

  const handleFilterChange = (userFilter) => {
    setUserFilter(userFilter);
  };

  useEffect(() => {
    setShowEditUser(editedUser ? true : false);
  }, [editedUser]);

  useEffect(() => {
    console.log("errorText", errorText);
  }, [errorText]);

  const onClickResetPassword = (e, id) => {
    e.stopPropagation();
    //const callResponse = UpdateUserPassword(currentToken, id, password, setErrorText)
    setEditedUserId(id);
    setShowResetPassword(true);
  };

  const onClickDeleteUser = (e, id) => {
    e.stopPropagation();
    //const callResponse = UpdateUserPassword(currentToken, id, password, setErrorText)
    setEditedUserId(id);
    console.log("onClickDeleteUser", id);
    setShowDeleteConfirmation(true);
  };

  const ResetPasswordIcon = (params) => {
    return (
      <Tooltip title="Reset Password" arrow placement="top">
        <IconButton size="small" sx={{ left: "25%" }} onClick={(e) => onClickResetPassword(e, params.id)}>
          <RestartAlt />
        </IconButton>
      </Tooltip>
    );
  };

  const DeleteUserIcon = (params) => {
    return (
      <Tooltip title="Delete User" arrow placement="top">
        <IconButton size="small" sx={{ left: "25%" }} onClick={(e) => onClickDeleteUser(e, params.id)}>
          <DeleteIcon />
        </IconButton>
      </Tooltip>
    );
  };

  const userGridColumns = [
    ...UserTableColumns,
    {
      field: "Password",
      headerName: "Password",
      sortable: false,
      renderCell: ResetPasswordIcon,
      headerClassName: "data-grid-header",
    },
    {
      field: "Delete",
      headerName: "Delete User",
      sortable: false,
      renderCell: DeleteUserIcon,
      headerClassName: "data-grid-header",
    },
  ];

  const updatePassword = async (password) => {
    setErrorText();
    const errors = await UpdateUserPassword(currentToken, editedUserId, password, setErrorText);
    const hasError = errors && errors.length > 0 ? true : false;

    if (!hasError) {
      setSuccessMsg("Password Reset");
      setSuccessAlert(true);
      setShowResetPassword(false);
    } else {
      setErrorMsg(errors);
      setErrorAlert(true);
    }
  };

  const RemoveUser = async () => {
    setErrorText();
    const errors = await DeleteUser(currentToken, editedUserId);
    //const errors = [];

    const hasError = errors && errors.length > 0 ? true : false;
    setShowDeleteConfirmation(false);

    if (!hasError) {
      setSuccessMsg("User Removed");
      setSuccessAlert(true);
      setUserData(null); // reload userData
    } else {
      setErrorMsg(errors);
      setErrorAlert(true);
    }
  };  

  const showUserSuccessMessage = (msg) => {
    setShowEditUser(false);
    setUserData(null);
    setSuccessMsg(msg);
    setSuccessAlert(true);
  };

  const updateUser = (response, error) => {
    const isUpdate = editedUser && editedUser.id.length > 0 ? true : false;
    const hasError = error && error.length > 0 ? true : false;
    const hasResponse = response && response.email.length > 0 ? true : false;

    console.log("updateuser ", error, isUpdate, hasError, hasResponse);

    if (isUpdate && hasResponse && !hasError) {
      showUserSuccessMessage(`User ${response.email} has been updated`);
    } else if (!isUpdate && hasResponse && !hasError) {
      showUserSuccessMessage(`User ${response.email} has been created`);
    } else {
      if (hasResponse) setErrorMsg(`ERROR :: ${response.email} has NOT been ${isUpdate ? "updated" : "created"}`);
      else setErrorMsg(`ERROR :: An error has occured - ${error}`);

      setErrorAlert(true);
    }
  };

  const handleEditUser = (params) => {
    if (params && params.row) setEditedUser({ ...params.row });
  };

  const handleNewUserClick = () => {
    setEditedUser({
      id: "",
      firstName: "",
      lastName: "",
      auditName: "",
      email: "",
      organisation: "",
      active: true,
      rights: [],
      extraInformation: [],
    });
  };

  const renderForm = () => {
    return (
      <>
        <Snackbar open={errorAlert} autoHideDuration={4000} onClose={() => setErrorAlert(false)}>
          <Alert onClose={() => setErrorAlert(false)} severity="error" sx={{ width: "100%" }}>
            {errorMsg}
          </Alert>
        </Snackbar>

        <Snackbar open={successAlert} autoHideDuration={4000} onClose={() => setSuccessAlert(false)}>
          <Alert onClose={() => setSuccessAlert(false)} severity="success" sx={{ width: "100%" }}>
            {successMsg}
          </Alert>
        </Snackbar>
        <Grid
          container
          spacing={1}
          sx={{ paddingTop: theme.spacing(10) }}
          direction="column"
          justifyContent="space-between"
          alignItems="left"
        >
          <Grid item xs>
            {showResetPassword ? (
              <ResetPasswordDialog
                closeDialogHandler={() => setShowResetPassword(false)}
                continueHandler={async (pwd) => await updatePassword(pwd)}
                continueError={errorText}
              />
            ) : (
              <Fragment />
            )}
          </Grid>
          <Grid item xs>
            {showEditUser ? (
              <EditUserWizard
                userDetails={editedUser}
                closeDialogHandler={() => setEditedUser()}
                updateUserHandler={(response, errors) => updateUser(response, errors)}
                continueError={errorText}
              />
            ) : (
              <Fragment />
            )}
          </Grid>
          <Grid item xs>
            { 
              showDeleteConfirmation ? (
              <ConfirmationDialog
                title={`Delete User ${removedUserName ?? ""}`}
                message={`Are you sure you want to delete user : ${removedUserName ?? ""}?`}
                OkClickHandler={async () => await RemoveUser()} 
                CancelClickHandler={() => setShowDeleteConfirmation(false)}
              />
            ) : (
              <Fragment />
            )}
          </Grid>
          <Grid item xs sx={{ ml: theme.spacing(12), mr: theme.spacing(12) }}>
            {errorText ? <Alert severity="error">{errorText}</Alert> : <Fragment />}
            <Grid container spacing={1} direction="row" alignItems="center" justifyContent="space-between">
              <Grid item>
                <Typography variant="h4" component="div" sx={{ paddingTop: theme.spacing(1), mb: theme.spacing(1) }}>
                  User Management
                </Typography>
                <Typography variant="subtitle2" sx={{ pb: "8px" }}>
                  This enables users to be created and managed for use with ADS Products. You can filter out users
                  below.
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          container
          sx={{ width: "100%" }}
          spacing={1}
          direction="column"
          justifyContent="space-between"
          alignItems="left"
        >
          <Grid container justifyContent="center">
            <Card variant="outlined" sx={{ width: "100%", display: "flex", maxWidth: "90%", border: 0 }}>
              <CardContent sx={{ width: "100%" }}>
                <Typography variant="subtitle2" sx={{ color: adsBlueA }}>
                  Filters
                </Typography>
                <UserActions
                  filters={userFilter}
                  organisations={userOrganisations}
                  handleNewUserClick={handleNewUserClick}
                  onFilterChange={(filter) => handleFilterChange(filter)}
                />
                <UserListGrid
                  columns={userGridColumns}
                  rows={filteredData}
                  height={"70vh"}
                  onDoubleClick={(params) => handleEditUser(params)}
                  sortColumn={dataSort}
                  updateSortColumn={(sort) => setDataSort(sort)}
                />
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </>
    );
  };

  const renderBasic = () => {
    return (
      <Grid
        container
        spacing={1}
        sx={{ paddingTop: theme.spacing(50) }}
        direction="column"
        justifyContent="space-between"
        alignItems="left"
      >
        <Grid item>
          <h1>Loading data, please wait...</h1>
        </Grid>
      </Grid>
    );
  };

  const renderPage = () => {
    return !isLoading && currentToken ? renderForm() : renderBasic();
  };

  return renderPage();
};

export default HomePage;
