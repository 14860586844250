import React, { useEffect, useState } from "react";
import { StyledWizardDialog, StyledWizardDialogTitle } from "./StyledWizardDialog";
import { Typography, DialogActions, DialogContent, Grid, TextField } from "@mui/material";
import { FormRowStyle, FormInputStyle, TextFieldLabel } from "../../utils/AAStyles";
import { useTheme } from "@mui/material/styles";
import ContinueButton from "../../components/continueButton";
import AntSwitch from "../../components/AntSwitch";

const UserDetailsDialog = ({ userDetails, closeDialogHandler, continueHandler, isSuperAdmin, defaultOrganisation }) => {
  const theme = useTheme();

  const [firstName, setFirstName] = useState(userDetails ? userDetails.firstName : "");
  const [lastName, setLastName] = useState(userDetails ? userDetails.lastName : "");
  const [email, setEmail] = useState(userDetails ? userDetails.email : "");
  const [organisation, setOrganisation] = useState(
    userDetails && userDetails.organisation.length > 0 ? userDetails.organisation : `${defaultOrganisation}`
  );
  const [auditName, setAuditName] = useState(userDetails ? userDetails.auditName : "");

  const isNewUser = !(userDetails && userDetails.id.length > 0);

  const firstNameMeta = {
    name: "FirstName",
    minLength: 2,
    maxLength: 50,
    required: true,
    update: setFirstName,
    property: firstName,
    isReadOnly: false,
  };
  const lastNameMeta = {
    name: "LastName",
    minLength: 2,
    maxLength: 50,
    required: true,
    update: setLastName,
    property: lastName,
    isReadOnly: false,
  };
  const emailMeta = {
    name: "Email",
    minLength: 10,
    maxLength: 100,
    required: true,
    update: setEmail,
    property: email,
    isReadOnly: false,
  };
  const auditNameMeta = {
    name: "AuditName",
    minLength: 5,
    maxLength: 100,
    required: true,
    update: setAuditName,
    property: auditName,
    isReadOnly: !isNewUser,
  };
  const organisationMeta = {
    name: "Organisation",
    minLength: 3,
    maxLength: 150,
    required: true,
    update: setOrganisation,
    property: organisation,
    isReadOnly: !isSuperAdmin,
  };

  const alwaysEditabledControls = [firstNameMeta, lastNameMeta, organisationMeta, emailMeta];
  //const editControls = (isNewUser) ? [...alwaysEditabledControls, auditNameMeta] : [...alwaysEditabledControls, auditNameMeta];
  const editControls = [...alwaysEditabledControls, auditNameMeta];

  const [isActive, setIsActive] = useState(userDetails && userDetails.active);
  const [hasContinued, setHasContinued] = useState(false);
  const [contineEnabled, setContinueEnabled] = useState(true);

  useEffect(() => {
    validateFields();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [firstName, lastName, email, auditName, organisation]);

  const onCancelHandler = (event, reason) => {
    if (reason !== "backdropClick") closeDialogHandler();
  };

  const handleContinueClick = async () => {
    setHasContinued(true);
    const updatedUserDetails = {
      ...userDetails,
      firstName: firstName,
      lastName: lastName,
      email: email,
      organisation: organisation,
      auditName: auditName,
      active: isActive,
    };
    continueHandler(updatedUserDetails);
  };

  const isFieldValid = (meta, value) => {
    return value && value.trim().length >= meta.minLength && value.trim().length < meta.maxLength;
  };

  const getHelperText = (meta, value) => {
    return hasContinued && !isFieldValid(meta, value)
      ? `${meta.name} must be between ${meta.minLength} and ${meta.maxLength} chars`
      : `${meta.maxLength - value.length} chars`;
  };

  const onUserDetailsChange = (handlerFunc, value, meta) => {
    if (value.length <= meta.maxLength) handlerFunc(value);
  };

  const validateFields = () => {
    setContinueEnabled(editControls.reduce((x, y) => x && isFieldValid(y, `${y.property}`), true));
  };

  return (
    <StyledWizardDialog
      onClose={onCancelHandler}
      open
      //aria-labelledby="confirm-edit-loss-dialog"
      fullWidth
      disableEnforceFocus
      disableEscapeKeyDown
      maxWidth="sm"
    >
      <StyledWizardDialogTitle
        id="customized-dialog-title"
        onClose={onCancelHandler}
        title={isNewUser ? "New User Details" : "Update User Details"}
      />
      <DialogContent sx={{ marginTop: theme.spacing(1) }}>
        {editControls.map((x) => {
          return (
            <Grid key={x.name} container justifyContent="flex-start" alignItems="center" sx={FormRowStyle()}>
              <Grid item xs={3}>
                <Typography
                  id={`aa-text-label-${x.name}`}
                  variant="body2"
                  color="initial"
                  align="left"
                  sx={TextFieldLabel(hasContinued && !isFieldValid(x, `${x.property}`))}
                >
                  {x.name}
                </Typography>
              </Grid>
              <Grid item xs={9}>
                <TextField
                  id={`aa-text-textfield-${x.name}`}
                  error={hasContinued && !isFieldValid(x, `${x.property}`)}
                  sx={FormInputStyle()}
                  fullWidth
                  required={x.required}
                  variant="outlined"
                  margin="dense"
                  size="small"
                  value={x.property}
                  helperText={
                    <Typography sx={{ position: "absolute", right: 0, mt: -0.5 }}>
                      {getHelperText(x, `${x.property}`)}
                    </Typography>
                  }
                  onChange={(e) => onUserDetailsChange(x.update, e.target.value, x)}
                  aria-labelledby={`aa-text-label-${x.name}`}
                  disabled={x.isReadOnly}
                />
              </Grid>
            </Grid>
          );
        })}

        <Grid container justifyContent="flex-start" alignItems="center" sx={FormRowStyle()}>
          <Grid item xs={3}>
            <Typography id={`aa-text-label-active`} variant="body2" color="initial" align="left" sx={FormRowStyle()}>
              Active
            </Typography>
          </Grid>
          <Grid item xs={1}>
            <AntSwitch onClick={() => setIsActive(!isActive)} checked={isActive} />
          </Grid>
          <Grid item xs={8}>
            <Typography>{isActive ? "Active" : "Disabled"}</Typography>
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions sx={{ marginBottom: theme.spacing(1) }}>
        <ContinueButton continueHandler={handleContinueClick} enabled={contineEnabled} />
      </DialogActions>
    </StyledWizardDialog>
  );
};

export default UserDetailsDialog;
