import React from "react";
import PropTypes from "prop-types";

import LoginDialogV1 from "./LoginDialogV1";
import LoginDialogV2 from "./LoginDialogV2";
import AuthenticateDialog from "./AuthenticateDialog";


LoginDialog.propTypes = {
  title: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
};

function LoginDialog({ title, message,version }) {

  console.log("version",version, process.env.REACT_APP_SECURITY_VERSION,version === "1");

  return (version === "1") ?
    (
        <LoginDialogV1
            title={title}
            message={message}
        />

     ) :
    (   
      <>
      <AuthenticateDialog
        title="IDOX ADS Security Authenticate"
        message="Authenticate with the code emailed to you."
      />

      <LoginDialogV2
        title={title}
        message={message}
      />
      </>
    )
}

export default LoginDialog;
