/* #region header */
/**************************************************************************************************
//
//  Description: Aligned Assets Navigation Bar component
//
//  Copyright:    © 2021 Aligned Assets Limited
//
//--------------------------------------------------------------------------------------------------
//
//  Modification History:
//
//  Version Date     Modifier            Issue# Description
//#region Version 1.0.0.0 changes
//    001   02.07.21 Sean Flook         WI39??? Initial Revision.
//#endregion Version 1.0.0.0 changes
//
//--------------------------------------------------------------------------------------------------
/* #endregion header */

/* #region imports */

import { Drawer, Grid, Box } from "@mui/material";
import AAUserAvatar from "./AAUserAvatar";
import { useTheme } from "@mui/material/styles";

/* #endregion imports */

const AANavContent = (props) => {
  const theme = useTheme();

  const navBarWidth = "60px";

  return (
    <Drawer
      variant="permanent"
      sx={{
        zIndex: (theme) => theme.zIndex.drawer + 1,
        width: navBarWidth,
        flexShrink: 0,
        [`& .MuiDrawer-paper`]: { width: navBarWidth, boxSizing: "border-box" },
      }}
    >
      {/* <Toolbar /> */}
      <Box sx={{ overflow: "clip" }}>
        <Grid
          sx={{
            paddingTop: theme.spacing(0.5),
            height: "91vh",
          }}
          container
          direction="column"
          justifyContent="space-between"
          alignItems="left"
        >
          <Grid item xs sx={{ ml: theme.spacing(1.5) }}>
            <img src="/images/Idox_Logo.svg" alt="IDOX Group" width="36" />
          </Grid>
          <Grid item>
            <Grid container direction="column" alignItems="center" justifyContent="flex-end">
              <AAUserAvatar />
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Drawer>
  );
};

AANavContent.propTypes = {};
AANavContent.defaultProps = {};

export default AANavContent;
