//#region header */
/**************************************************************************************************
//
//  Description: All the styling used by the app
//
//  Copyright:    © 2021 Aligned Assets Limited
//
//--------------------------------------------------------------------------------------------------
//
//  Modification History:
//
//  Version Date     Modifier            Issue# Description
//#region Version 1.0.0.0 changes
//    001   02.07.21 Sean Flook         WI39??? Initial Revision.
//#endregion Version 1.0.0.0 changes
//
//--------------------------------------------------------------------------------------------------
//#endregion header */

/* #region imports */

import CheckIcon from "@mui/icons-material/Check";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { grey } from "@mui/material/colors";
import { useTheme } from "@mui/material/styles";
import { adsBlueA, adsLightBlue, adsWhite } from "./ADSColours";

/* #endregion imports */

export function GetDrawerWidth() {
  return "400px";
}

export function GetNavBarWidth() {
  return "285px";
}

export function ActionIconStyle() {
  return {
        // color: "#535353",
    color: "#2A6EBB",
    "&:hover": {
      color: "#7f7f7f",
    },
  };
}

export function ActionMapIconStyle() {
  const theme = useTheme();
  return {
    backgroundColor: "#FFFFFF",
    color: theme.palette.background.contrastText,
    "&:hover": {
      color: "#2A6EBB",
    },
  };
}

export function TextFieldLabel(hasError) {
  const theme = useTheme();
  return {
    paddingBottom: theme.spacing(2),
    color: hasError ? "#ff0000" : theme.palette.main,
  };
}

export function FormRowStyle(hasError) {
  const theme = useTheme();
  return hasError
    ? {
        paddingTop: theme.spacing(1),
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(2),
        color: theme.palette.text.secondary,
        backgroundColor: "rgba(196, 5, 28, 0.1)",
        display: "flex",
        justifyContent: "flex-start",
      }
    : {
        paddingTop: theme.spacing(1),
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(2),
        color: theme.palette.text.secondary,
        display: "flex",
        justifyContent: "flex-start",
      };
}

export function FormInputStyle(hasError) {
  const theme = useTheme();
  return hasError
    ? {
        backgroundColor: theme.palette.background.paper,
        "&$outlinedInputFocused": {
          borderColor: `${theme.palette.primary.main} !important`,
        },
        "&$notchedOutline": {
          borderWidth: "1px",
          borderColor: "#C4051C",
          // borderColor: `${red[500]} !important`,
        },
        "&.MuiFormHelperText-root": {
          textAlign: "right",
          marginRight: 0,
        },
      }
    : {
        //backgroundColor: "#ff0000",
        backgroundColor: theme.palette.background.paper,
        "&$outlinedInputFocused": {
          borderColor: `${theme.palette.primary.main} !important`,
        },
        "&$notchedOutline": {
          borderWidth: "1px",
          borderColor: "#e1e1e1 !important",
        },
        "& .MuiFormHelperText-contained": {
          textAlign: "right",
          marginRight: 0,
        },
      };
}

export function FormDateInputStyle(hasError) {
  const theme = useTheme();
  if (hasError)
    return {
      backgroundColor: theme.palette.background.paper,
      marginTop: 0,
      "&$outlinedInputFocused": {
        borderColor: `${theme.palette.primary.main} !important`,
      },
      "&$notchedOutline": {
        borderWidth: "1px",
        borderColor: "#C4051C",
        // borderColor: `${red[500]} !important`,
      },
    };
  else
    return {
      backgroundColor: theme.palette.background.paper,
      marginTop: 0,
      "&$outlinedInputFocused": {
        borderColor: `${theme.palette.primary.main} !important`,
      },
      "&$notchedOutline": {
        borderWidth: "1px",
        borderColor: "#e1e1e1 !important",
      },
    };
}

export function FormSelectInputStyle(hasError) {
  const theme = useTheme();
  if (hasError)
    return {
      backgroundColor: theme.palette.background.paper,
      display: "inline-flex",
      "&$outlinedInputFocused": {
        borderColor: `${theme.palette.primary.main} !important`,
      },
      "&$notchedOutline": {
        borderWidth: "1px",
        borderColor: "#C4051C",
        // borderColor: `${red[500]} !important`,
      },
    };
  else
    return {
      backgroundColor: theme.palette.background.paper,
      display: "inline-flex",
      "&$outlinedInputFocused": {
        borderColor: `${theme.palette.primary.main} !important`,
      },
      "&$notchedOutline": {
        borderWidth: "1px",
        borderColor: "#e1e1e1 !important",
      },
    };
}

export function AddUserStyle() {
  return {
    color: adsWhite,
    backgroundColor: adsBlueA,
    textTransform: "none",
    "&:hover": {
      backgroundColor: adsLightBlue,
      color: adsWhite,
    },
  };
}

export function ResetFiltersStyle() {
  return {
    color: adsWhite,
    backgroundColor: adsBlueA,
    textTransform: "none",
    "&:hover": {
      backgroundColor: adsLightBlue,
      color: adsWhite,
    },
  };
}

export function ToolbarStyle() {
  const theme = useTheme();
  return {
    backgroundColor: "#ffffff",
    borderBottomWidth: "1px",
    borderBottomStyle: "solid",
    borderBottomColor: "#d7d7d7",
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1.5),
  };
}

export function StreetToolbarStyle() {
  const theme = useTheme();
  return {
    backgroundColor: "#ffffff",
    borderBottomWidth: "1px",
    borderBottomStyle: "solid",
    borderBottomColor: "#d7d7d7",
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1.5),
    height: "34px",
    width: "100%",
  };
}

export function StreetDataFormStyle() {
  return {
    overflowY: "auto",
    width: "100%",
    height: "79vh",
  };
}

export function PropertyToolbarStyle() {
  const theme = useTheme();
  return {
    backgroundColor: "#ffffff",
    borderBottomWidth: "1px",
    borderBottomStyle: "solid",
    borderBottomColor: "#d7d7d7",
    paddingLeft: theme.spacing(0),
    paddingRight: theme.spacing(1),
    height: "34px",
    width: "100%",
  };
}

export function PropertyDataFormStyle() {
  return {
    overflowY: "auto",
    width: "100%",
    height: "79vh",
  };
}

export function SearchDataFormStyle() {
  return {
    overflowY: "auto",
    width: "100%",
    height: "84vh",
  };
}

export function RecordCountStyle(isHover) {
  const theme = useTheme();

  if (isHover)
    return {
      width: theme.spacing(2),
      height: theme.spacing(2),
      backgroundColor: "#2A6EBB",
    };
  else
    return {
      width: theme.spacing(2),
      height: theme.spacing(2),
      backgroundColor: grey[300],
    };
}

export function GetTabIconStyle(dataLength, isActive) {
  const theme = useTheme();
  // console.log("[SF] GetTabIconStyle", dataLength);
  const badgeColour = isActive ? "#2A6EBB" : "#535353";
  if (dataLength < 10)
    return {
      width: theme.spacing(2),
      height: theme.spacing(2),
      color: theme.palette.getContrastText(badgeColour),
      backgroundColor: badgeColour,
      borderRadius: "18px",
      fontFamily: "Open Sans",
      marginLeft: theme.spacing(0.5),
    };
  else if (dataLength < 100)
    return {
      width: theme.spacing(3),
      height: theme.spacing(2),
      color: theme.palette.getContrastText(badgeColour),
      backgroundColor: badgeColour,
      borderRadius: "18px",
      fontFamily: "Open Sans",
      marginLeft: theme.spacing(0.5),
    };
  else
    return {
      width: theme.spacing(4),
      height: theme.spacing(2),
      color: theme.palette.getContrastText(badgeColour),
      backgroundColor: badgeColour,
      borderRadius: "18px",
      fontFamily: "Open Sans",
      marginLeft: theme.spacing(0.5),
    };
}

export function GetAlertStyle(result) {
  if (result)
    return {
      backgroundColor: "#2A6EBB",
    };
  else
    return {
      backgroundColor: "#C4051C",
    };
}

export function GetAlertIcon(result) {
  if (result) return <CheckIcon fontSize="inherit" />;
  else return <ErrorOutlineIcon fontSize="inherit" />;
}

export function GetAlertSeverity(result) {
  if (result) return "success";
  else return "error";
}

export function ErrorIconStyle() {
  const theme = useTheme();

  return {
    color: "#C4051C",
    width: theme.spacing(2.2),
    height: theme.spacing(2.2),
  };
}
