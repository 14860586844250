import React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { Typography} from "@mui/material";
import { adsBlueA, adsWhite } from '../../utils/ADSColours';



export const StyledWizardDialog = styled(Dialog)(({ theme }) => 
({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
  '& .MuiDialog-paper': {
    borderRadius: 2
  },

})
);


StyledWizardDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
  };
  

export function StyledWizardDialogTitle(props) {
  const { children, title, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2, color: adsWhite, backgroundColor: adsBlueA}} {...other}>
      <Typography
              id="aa-text-dialog-title"
              variant="body1"
              color={adsWhite}
              align="left"
              sx={{ paddingRight: 10 }}
            >
            {title}
        </Typography>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            marginLeft:5,
            color: adsWhite,
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}
